import { VideoPopup } from './common/videoPopup';
import { VideoInline } from './common/videoInline';

window.HMSVideo = class Video {
  constructor() {
    this.init();
  }

  init() {
    if (VideoInline.isOnPage()) {
      new VideoInline();
    }
    if (VideoPopup.isOnPage()) {
      new VideoPopup();
    }
  }
};
