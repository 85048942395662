/* global VideoPlayer */
const playerSelector = '.video-player';

export class VideoInline {
  static isOnPage() {
    return $(playerSelector).length !== 0;
  }

  constructor() {
    this.init();
  }

  init() {
    $.each($(playerSelector), (index, player) => new VideoPlayer(player));
  }
}